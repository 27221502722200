import { MetaInfo } from 'vue-meta/types/vue-meta';

export const TITLE = 'Chompy(チョンピー) 国内発の新しいフードデリバリー';

export const DESCRIPTION =
  'Chompyは、国内発の新しいフードデリバリーサービスです。人から人へ「おいしい」をお届けする、温もりを感じられるサービスを目指しています。';

export const TWITTER_ACCOUNT = '@chompy_jp';

export function createMetaInfo({
  title,
  description,
  pathname,
  imageUrl,
  twitterCreator
}: {
  title?: string;
  description?: string;
  pathname?: string;
  imageUrl?: string;
  twitterCreator?: string;
}): MetaInfo {
  const metaInfo: MetaInfo = {
    title: title ? `${title} | ${TITLE}` : TITLE,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: description ?? DESCRIPTION
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title ? `${title} | ${TITLE}` : TITLE
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description ?? DESCRIPTION
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: pathname ? `${process.env.BASE_URL}${pathname}` : `${process.env.BASE_URL}/`
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: imageUrl ?? `${process.env.BASE_URL}/assets/images/meta/ogp.jpg`
      },
      {
        hid: 'og:locale',
        property: 'og:locale',
        content: 'ja_JP'
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: title ? `${title} | ${TITLE}` : TITLE
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: description ?? DESCRIPTION
      },
      {
        hid: 'twitter:url',
        name: 'twitter:url',
        content: pathname ? `${process.env.BASE_URL}${pathname}` : `${process.env.BASE_URL}/`
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageUrl ?? `${process.env.BASE_URL}/assets/images/meta/ogp.jpg`
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        content: TWITTER_ACCOUNT
      },
      {
        hid: 'facebook-domain-verification',
        name: 'facebook-domain-verification',
        content: 'm87yeiiwt73rgpnvlfjs0akx6yoy9y'
      }
    ]
  };

  if (twitterCreator) {
    try {
      metaInfo.meta?.push({
        hid: 'twitter:creator',
        name: 'twitter:creator',
        content: twitterCreator.startsWith('@')
          ? twitterCreator
          : `@${new URL(twitterCreator).pathname.replace('/', '')}`
      });
    } catch (error) {
      console.error(error);
    }
  }

  return metaInfo;
}
