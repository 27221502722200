import { TimeOfDay } from 'chompy-proto/proto-gen/ts/google/type/timeofday_pb';

export function toDate(timeOfDay: TimeOfDay.AsObject | undefined) {
  const date = new Date();

  if (timeOfDay) {
    date.setHours(timeOfDay.hours);
    date.setMinutes(timeOfDay.minutes);
    date.setSeconds(timeOfDay.seconds);
  }

  return date;
}

export function toDeliveryMinutes(averageCookingMinutes: number) {
  return averageCookingMinutes + 15;
}
